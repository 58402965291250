body{
    color: #656566;
    overflow: hidden;
}
h3{
    margin: .5em 0 .5em 0;    
    font-size: 1.25em;
}
.file-drop{
    z-index: -1;
    top: 0;
    right: 0;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2em;
    //border: 1px solid black;
    //color: black;
    //padding: 20px;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.5);
    visibility: visible;
    //transition: visibility .25s;
}
.file-drop-visible{
    z-index: 100000;
}