.notification-container{
    position: fixed;
    display: flex;
    overflow: hidden;
    z-index: 50;
    max-width: 40%;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 5px;
    //visibility: hiden;
    bottom: 20px;
    //right: 20px;
    transition: all .5s;
    
    .type, .content{
        padding: 1.5em 1.5em 1.5em 1.5em;
    }
    .type{
        width: 15%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content{
        min-width: 85%;
        background-color: #fff;
        width: auto;
        text-align: center;
        transition: all .5s;
        .switch{
            width: auto !important;
            height: auto !important;
            margin: 1em 0 1em 0;
            overflow: hidden;
            border-radius: 5px;
            background-color: #fff;
            border: 1px solid rgba(0,0,0,.1);
            display: flex;
            transition: all .5s;
            span{
                white-space: nowrap;
                padding: 1em;
                cursor: pointer;
                width: 50%;
            }
            .active{
                background-color: #336699;
                color: #fff;
            }
        }
    }
    .close-btn{
        position: absolute;
        top: .25em;
        right: .5em;
        cursor: pointer;
    }
    .warning{
        background-color: #f39c12;
    }
    .success{
        background-color: #2ecc71;
    }
    .error{
        background-color: #e74c3c;
    }
    .spinner{
        animation-duration: 1s;
        animation-name: turn;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-timing-function: linear;
    }
    @keyframes turn {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
.visible{
    visibility: visible;
    opacity: 1;
    right: 20px;
}
.hidden{
    visibility: hidden;
    opacity: 0;
    right: -100px;
}
@media (max-width: 768px) {
    .notification-container{
        max-width: calc( 100% - 40px );
        top: 20px;
        bottom: unset;
    }
}